<template>
  <div>
    <div v-if="availabilities.length" class="my-5">
      <div class="products-list">
        <div
          v-for="availability in availabilities"
          :key="availability.id"
          class="row mx-0 products-list-item py-4 px-2 my-3"
        >
          <div class="col-md-5">
            <div class="position-relative">
              <b-carousel
                id="carousel-fade"
                :interval="3000"
                style="text-shadow: 0px 0px 2px #000"
                fade
                indicators
                img-width="1024"
                img-height="480"
                ><div>
                  <template v-if="availability.roomImages?.length">
                    <div v-for="url of roomImages(availability)" :key="url">
                      <b-carousel-slide
                        style="max-height: 250px"
                        :img-src="url"
                      ></b-carousel-slide>
                    </div>
                  </template>
                  <template v-else>
                    <b-carousel-slide
                      v-for="i of 1"
                      :key="i"
                      img-src="/images/property-default-image.jpg"
                    ></b-carousel-slide>
                  </template>
                </div>
              </b-carousel>
            </div>
          </div>
          <div class="col-md-7">
            <h4 class="mb-4">{{ productName(availability) }}</h4>
            <p>
              {{
                availability.sellableUnit.names.length
                  ? availability.sellableUnit.names[0].text
                  : ""
              }}
            </p>
            <p>
              {{
                availability.descriptions?.length
                  ? availability.descriptions[0]?.text
                  : ""
              }}
            </p>
          </div>
          <div class="selectors mt-3 pt-3 mx-2">
            <div class="row">
              <div class="col-md-5">
                <div
                  class="small"
                  :class="`text-${availability.meal ? 'success' : 'danger'}`"
                >
                  <img
                    v-if="availability.meal"
                    src="../../assets/icons/meal-success.svg"
                    class="mr-1"
                    alt="meal"
                  />
                  <img
                    v-else
                    src="../../assets/icons/meal.svg"
                    class="mr-1"
                    alt="meal"
                  />
                  <span v-if="availability?.meal?.breakfast">{{
                    $t("reservation.breakfast")
                  }}</span>
                  <span v-else>{{
                    availability.meal ? "Meal" : "No meal"
                  }}</span>
                  Included
                </div>
                <div class="mt-2">{{ rateDetails(availability) }}</div>
                <div class="small text-main mt-2">
                  <span
                    role="button"
                    v-b-popover.bottom.hover="rateDetails(availability)"
                    >{{ $t("property.rateDetails") }}</span
                  >
                  |
                  <span
                    role="button"
                    v-b-popover.bottom.hover.html="
                      cancellationPolicyText(availability)
                    "
                    >{{ $t("property.cancellationPolicy") }}</span
                  >
                </div>
              </div>
              <div class="col-md-3">
                <div class="h-100 d-md-flex d-block">
                  <div class="my-md-auto my-1">
                    <img
                      v-for="i in guests"
                      :key="i"
                      src="../../assets/icons/person.svg"
                      class="mr-1"
                      alt="person"
                    />
                    <span class="ml-1 text-14">{{
                      $t("property.persons")
                    }}</span>
                  </div>
                </div>
              </div>
              <div class="col-md-4">
                <div
                  class="h-100 mt-md-2 mt-0 d-flex align-items-center justify-content-between"
                >
                  <div>
                    <h3 class="text-main font-weight-bold actual-amount">
                      {{ availability.currencyCode }}
                      {{ availability.amount.toFixed(2) }}
                    </h3>
                  </div>
                  <div>
                    <button
                      @click="bookNow(availability)"
                      class="btn btn-main px-4"
                    >
                      {{ $t("property.bookNow") }}
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div v-if="fetched && !availabilities.length" class="p-5 mt-5 text-center">
      {{ $t("property.noDeals") }}
    </div>
    <div v-else-if="!fetched" class="py-5 mt-5 text-center text-md">
      <h5>{{ $t("property.selectStay") }}</h5>
    </div>
  </div>
</template>

<script>
export default {
  name: "EhotelList",
  props: {
    availabilities: {
      type: Array,
      required: true,
    },
    fetched: {
      type: Boolean,
      default: false,
    },
    guests: {
      type: Number,
      default: 2,
    },
    rooms: {
      type: Number,
      default: 1,
    },
  },
  data() {
    return {
      cart: [],
    };
  },
  methods: {
    bookNow(availability) {
      this.$emit("bookNow", {
        id: availability.id,
        quantity: this.rooms,
        guests: this.guests,
        price: availability.amount,
        currencyCode: availability.currencyCode,
        product: availability,
      });
    },
    roomImages(availability) {
      return availability.roomImages.slice(0, 3);
    },
    productName(availability) {
      return availability?.sellableUnit?.roomType;
    },
    rateDetails(availability) {
      let text = this.productName(availability);
      if (availability.meal) {
        text += ", " + this.$t("reservation.breakfast");
      }
      if (availability.cancelPolicies.nonRefundable) {
        text += " - " + this.$t("reservation.nonRefundableRate");
      }
      return text;
    },
    cancellationPolicyText(availability) {
      let policyText = "";
      availability.cancelPolicies.penaltyDescriptions.forEach(({ text }) => {
        policyText += `<li>${text}</li>`;
      });
      return `<ul>${policyText}</ul>`;
    },
    cartChanged(value, availability, field) {
      const idx = this.cart.findIndex(({ id }) => id === availability.id);
      if (idx === -1) {
        if (value > 0) {
          const data = {
            id: availability.id,
            quantity: 0,
            guests: 1,
            price: availability.amount,
            currencyCode: availability.currencyCode,
            product: availability,
          };
          data[field] = value;
          this.cart.push(data);
        }
      } else {
        if (value === 0) {
          this.cart.splice(idx, 1);
        } else {
          this.cart[idx][field] = value;
        }
      }
    },
    getCartValue(availability, field) {
      const item = this.cart.find(({ id }) => id === availability.id);
      return item ? item[field] : field === "quantity" ? 0 : 1;
    },
  },
};
</script>

<style>
.text-14 {
  font-size: 14px;
}
.actual-amount {
  font-size: 28px;
}

@media screen and (max-width: 600px) {
  .actual-amount {
    font-size: 20px;
    margin-bottom: 0;
  }
  .text-14 {
    font-size: 12px;
  }
}
</style>
